<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="代理商详情"
      left-text="返回"
      :right-text="!state.loading ? '更新' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="
        !state.loading &&
          $router.push({ name: 'AgentForm', query: { id: state.id } })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell-group title="用户信息">
        <van-cell title="昵称">
          <template #value>
            <img
              width="20"
              height="20"
              class="rounded-circle"
              :src="state.user.headimgurl"
            />
            {{ state.user.nickname }}
          </template>
        </van-cell>
        <van-cell title="手机" :value="state.user.phone" />
      </van-cell-group>
      <van-cell-group title="代理商信息">
        <van-cell title="代理名称" :value="state.agent_name" />
        <van-cell title="联系人" :value="state.contact_name" />
        <van-cell title="联系电话" :value="state.contact_phone" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast, Tag } from "vant";
import { getAgentDetail } from "@/api/agent.service";
export default {
  name: "AgentDetail",
  components: {
    [Tag.name]: Tag,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: 0,
      loading: true,
    });

    const getAgent = async (id) => {
      try {
        const { result } = await getAgentDetail(id);
        state.loading = false;
        for (const key in result) {
          state[key] = result[key];
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        getAgent(id);
      }
    });

    return { state, getAgent };
  },
};
</script>
